/** 店铺管理 */
export const columns = [
  {
    title: '基本信息',
    dataIndex: 'id',
    width: '350px',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '全部',
    dataIndex: 'role',
    scopedSlots: { customRender: 'role' },
    filters: [
      {
        text: '管理员',
        value: '管理员'
      }
    ],
    onFilter: (value, record) => record.name.indexOf(value) === 0,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend']
  },
  {
    title: '版本',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' }
    // customRender: (text) => text || '无数据'
  },
  {
    title: '国家',
    dataIndex: 'twoSalesAreaName',
    scopedSlots: { customRender: 'twoSalesAreaName' }
  },
  {
    title: '创建时间(UTC+08:00)',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: '到期时间(UTC+08:00)',
    dataIndex: 'expirationTime',
    scopedSlots: { customRender: 'expirationTime' }
  },
  {
    title: '当前状态',
    dataIndex: 'createStatus',
    scopedSlots: { customRender: 'createStatus' }
  },
  {
    title: '操作',
    width: '150px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

/** 用户管理 */
export const userColumns = [
  {
    title: '编号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '姓名',
    dataIndex: 'nickName',
    scopedSlots: { customRender: 'nickName' }
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    scopedSlots: { customRender: 'email' }
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

/** 抽佣 */
export const commissionColumns = [
  {
    title: 'Invoice账单编号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    title: '店铺',
    dataIndex: 'shopName',
    scopedSlots: { customRender: 'shopName' }
  },
  {
    title: '账期时间(UTC+08:00)',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'createTime' }
  },
  {
    title: '总金额(CNY)',
    dataIndex: 'totalAmount',
    scopedSlots: { customRender: 'totalAmount' }
  },
  {
    title: '佣金(CNY)',
    dataIndex: 'commission',
    scopedSlots: { customRender: 'commission' }
  },
  {
    title: '付款时间(UTC+08:00)',
    dataIndex: 'payTime',
    scopedSlots: { customRender: 'payTime' }
  },
  {
    title: '付款卡尾号',
    dataIndex: 'ppTransactionId',
    scopedSlots: { customRender: 'ppTransactionId' }
  },

  {
    title: '账单状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  }
]

/** 套餐 */
export const comboColumns = [
  {
    title: '套餐类型',
    dataIndex: 'comboName',
    scopedSlots: { customRender: 'comboName' }
  },
  {
    title: '账单编号',
    dataIndex: 'orderNO',
    scopedSlots: { customRender: 'orderNO' }
  },
  {
    title: '店铺',
    dataIndex: 'shopName',
    scopedSlots: { customRender: 'shopName' }
  },
  {
    title: '有效期(UTC+08:00)',
    dataIndex: 'expirationTime',
    scopedSlots: { customRender: 'expirationTime' }
  },
  {
    title: '抽佣比例',
    dataIndex: 'commission',
    scopedSlots: { customRender: 'commission' },
    customRender: (text) => text ? ((Number(text) * 100) + '%') : '暂无'
  },
  {
    title: '付款时间(UTC+08:00)',
    dataIndex: 'payTime',
    scopedSlots: { customRender: 'payTime' }
  },
  {
    title: '支付类型',
    dataIndex: 'payType',
    scopedSlots: { customRender: 'payType' }
  }
  // {
  //   title: '操作',
  //   dataIndex: 'id7',
  //   scopedSlots: { customRender: 'id7' }
  // }
]
