<template>
  <div class="shop-container" :style="{ minHeight : fillHeight? '100%' : '' }">
    <div class="title">
      <div class="page-title">
        {{ title }}
      </div>
      <slot name="titleSolt" />
    </div>
    <a-card style="text-align: left;" :bordered="false" :tab-list="tabList" :active-tab-key="tabKey" @tabChange="(key) => tabChange(key)">
      <slot />
    </a-card>
  </div>
</template>

<script>
export default {
  name: 'PageLayout',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    fillHeight: {
      type: Boolean,
      default: false
    },
    tabList: {
      type: Array,
      default: () => []
    },
    tabKey: {
      type: String,
      default: ''
    },
    tabChange: {
      type: Function,
      default: function() {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .shop-container{
    background: #ececec;
    padding: 30px;
  }

  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .page-title{
    font-size: 24px;
    font-weight: bold;
    text-align: left;
  }
</style>
