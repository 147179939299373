export const shopTypeMap = {
  'V1': {
    color: '#FF5F19',
    text: '爱心版'
  },
  'V2': {
    color: '#4BB756',
    text: '基础版'
  },
  'V3': {
    color: '#19397B',
    text: '专业版'
  },
  'V4': {
    color: '#121A43',
    text: '旗舰定制版'
  },
  'V5': {
    color: '#E35B5F',
    text: '试用版'
  }
}
