/**
 * @author  XuHongli
 * @date  2022/3/1 16:13
 * @version 1.0
 * @description Api 商店
 */
import request from '@/utils/request'

/** 查询有没有填完信息 */
export function checkForCompleteInformation(shopId) {
  return request({
    url: '/shop/checkForCompleteInformation',
    method: 'get',
    params: { shopId }
  })
}

/** 更新店铺信息 */
export function updateShop(data) {
  return request({
    url: '/shop/updateShop',
    method: 'post',
    data
  })
}

/** 我的应用列表 */
export function myApplicationList(params) {
  return request({
    url: '/userresources/userResourcesPage',
    method: 'get',
    params
  })
}

/** 集市应用列表 */
export function allApplicationList(params) {
  return request({
    url: '/vllshopresources/page',
    method: 'get',
    params
  })
}

/** 应用启用 */
export function startApplication(data) {
  return request({
    url: '/userresources/addUserResources',
    method: 'post',
    data
  })
}

/** 应用禁用 */
export function disableApplication(data) {
  return request({
    url: '/userresources/delUserResources',
    method: 'post',
    data
  })
}
