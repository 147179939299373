<template>
  <div v-show="show" class="loading">
    <div class="reverse-spinner" />
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: () => {
        return null
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.stopMove()
      } else {
        this.Move()
      }
    }
  },
  methods: {
    // 停止页面滚动
    stopMove() {
      const m = function(e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, { passive: false }) // 禁止页面滑动
    },
    // 开启页面滚动
    Move() {
      const m = function(e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' // 出现滚动条
      document.removeEventListener('touchmove', m, { passive: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinBack {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

.reverse-spinner {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 47%;
  top: 47%;
  transform: translate(-50%, -50%);
  border: 4px solid transparent;
  border-top-color: #1976d2;
  border-left-color: #1976d2;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 0.8s linear infinite;
}

.reverse-spinner::before {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  content: "";
  border: 4px solid transparent;
  border-top-color: #03a9f4;
  border-left-color: #03a9f4;
  border-radius: 50%;
  -webkit-animation: spinBack 1s linear infinite;
  animation: spinBack 1s linear infinite;
}
</style>
