<template>
  <page-layout title="店铺管理" :fill-height="true">
    <w-loading :show="showLoading" />
    <div slot="titleSolt">
      <a-button v-if="roleCode === 'MASTER_ACCOUNT'" type="primary" @click="createShopModal = true">
        创建店铺
      </a-button>
    </div>
    <div>
      <a-row>
        <a-col :span="4">
          <a-input v-model="searchObj.shopName" />
        </a-col>
        <a-col :span="4">
          <a-button type="primary" class="search" @click="handleSearch()">
            搜索
          </a-button>
        </a-col>
      </a-row>

      <a-table :row-key="(records) => records.id" :columns="columns" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange">
        <span slot="id" slot-scope="text,records">
          <div class="table-name">
            <!-- <div class="poit" /> 点 shopmanagement/shopManagementLogo -->
            <div class="shop-logo">
              <img :src="records.logo || '/images/shopmanagement/shopManagementLogo.png'" alt="" class="logo" srcset="">
            </div>
            <div class="text">
              <div>{{ records.shopName }}</div>
              <a-tag color="orange">
                {{ records.saleCategoryName || '暂未设置' }}
              </a-tag>
              <div>{{ records.site }}</div>
            </div>

          </div>
        </span>

        <span slot="role">
          管理员
        </span>

        <span slot="type">
          <!-- NOTE 处理旗舰版的文字颜色 -->
          <a-tag v-if="listData && listData.length > 0" :color="userInfo.comboCode | shopTypeColorFilter" :style="{ 'color': (userInfo.comboCode == 'V4')? '#FBC900' : ''}">
            {{ userInfo.comboCode | shopTypeFilter }}
          </a-tag>
        </span>

        <span slot="createStatus" slot-scope="text">
          <a-badge :color="text | statusTypeFilter" :text="text | statusFilter" />
        </span>

        <span slot="action" slot-scope="text,record">
          <a :style="{ 'color': (record.twoSalesAreaName === null || record.twoSalesAreaName === '')? '#E44D14' : '#62b7fc'}" @click="goShop(record)">{{ record.twoSalesAreaName === null || record.twoSalesAreaName === '' ? '完善信息' : '前往店铺' }}</a>
          <a v-if=" record.createStatus != 0 && roleCode === 'MASTER_ACCOUNT'" class="split" style="color:#62b7fc" @click.stop="delShop(record)"> 删除 </a>
        </span>
      </a-table>
      <!--  创建店铺窗口     -->
      <a-modal
        v-model="createShopModal"
        cancel-text="取消"
        ok-text="确定"
        title="创建店铺"
        @cancel="createShopModal = false"
        @ok="createShop"
      >
        <a-form-model
          ref="createShopForm"
          :colon="false"
          :model="createShopForm"
          :rules="createShopFormRules"
          class="form-style"
          hide-required-mark
          label-align="left"
          layout="vertical"
        >
          <a-form-model-item prop="shopName">
            <template slot="label">
              <div>
                <div class="label-text">
                  您的商店名称
                </div>
              </div>
            </template>
            <a-input
              v-model="createShopForm.shopName"
              class="input-style"
              placeholder="请输入您的商店名称"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!--  完善信息窗口    -->
      <a-modal
        v-model="editShopModal"
        cancel-text="取消"
        ok-text="确定"
        title="完善信息"
        @cancel="editShopModal = false"
        @ok="editShop"
      >
        <a-form-model
          ref="formTable"
          :colon="false"
          :model="form"
          :rules="rules"
          class="form-style"
          hide-required-mark
          label-align="left"
          layout="vertical"
        >
          <a-form-model-item prop="salesAreaId">
            <template slot="label">
              <div>
                <div class="label-text">
                  您商店的主要销售地区
                </div>
              </div>
            </template>
            <a-cascader
              v-model="form.salesAreaId"
              :field-names="{ label: 'name',value:'id',label:'name',children: 'children' }"
              :options="salesArea"
              placeholder="请选择"
            />
          </a-form-model-item>
          <a-form-model-item prop="currencyId">
            <template slot="label">
              <div>
                <div class="label-text">
                  您订单的结算货币是什么
                </div>
              </div>
            </template>
            <a-select
              v-model="form.currencyId"
              :filter-option="filterOption"
              placeholder="您选择订单的结算货币"
              show-search
              style="width: 200px"
            >
              <a-select-option v-for="(item,index) in currencyList" :key="index" :value="item.id">
                {{ item.showName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="saleCategoryId">
            <template slot="label">
              <div>
                <div class="label-text">
                  您经营的类型
                </div>
              </div>
            </template>
            <a-select
              v-model="form.saleCategoryId"
              :filter-option="filterOption"
              placeholder="请选择您经营的类型"
              show-search
              style="width: 200px"
            >
              <a-select-option v-for="(item,index) in saleCategoryList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </page-layout>
</template>

<script>
/**
 * @author  dengqingbin
 * @date  2022-02-17 17:08:11
 * @version 1.0
 * @description : 店铺管理
 */
import {
  delShopFormId,
  getList,
  getMd5Token,
  getShopList,
  getUserInfo,
  getUserDate,
  sendShopDeleteVerificationCode
} from '@/api/register'
import PageLayout from './components/pageLayout.vue'
import { columns } from './config/columns'
import { shopTypeMap } from './config/stringMap'
import { checkForCompleteInformation, updateShop } from '../../api/shop'
import { editform, getCountryList, getCurrencyList, shopInfo } from '../../api/register'
import { message } from 'ant-design-vue'
import { validatNumLetter } from '../../utils/validate'
import WLoading from './components/w-loading'
import { mapGetters } from 'vuex'

const statusMap = {
  0: {
    color: 'yellow',
    text: '搭建中'
  },
  1: {
    color: 'green',
    text: '搭建完成'
  },
  2: {
    color: 'red',
    text: '已过期'
  }
}

export default {
  inject: ['mask'],
  components: {
    WLoading,
    PageLayout
  },
  filters: {
    shopTypeFilter(type) {
      if (!type) { return }
      return shopTypeMap[type].text
    },
    shopTypeColorFilter(type) {
      if (!type) { return }
      return shopTypeMap[type].color
    },
    statusFilter(status) {
      return statusMap[status].text
    },
    statusTypeFilter(status) {
      return statusMap[status].color
    }
  },

  data() {
    /** 自定校验 */
    const validatNumAndLetter = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入正确的格式'))
      } else if (!validatNumLetter(value)) {
        callback(new Error('只能输入4-10位的小写字母和数字'))
      } else {
        callback()
      }
    }
    return {
      showLoading: false, // Loading
      /** 创建店铺信息校验 */
      createShopFormRules: {
        shopName: [{ required: true, trigger: 'blur', validator: validatNumAndLetter }]
      },
      /** 完善信息校验 */
      rules: {
        salesAreaId: [{ required: true, message: '请选择地区', trigger: 'change' }],
        currencyId: [{ required: true, message: '请选择结算货币', trigger: 'change' }],
        saleCategoryId: [{ required: true, message: '请选择店铺类型', trigger: 'change' }]
      },
      userInfo: {},
      columns,
      loading: false,
      pagination: {},
      listData: [],
      searchObj: { // 查询对象
        shopName: ''
      },
      editShopModal: false, // 完善信息窗口显示
      createShopModal: false, // 创建店铺窗口显示
      search: {},
      createShopForm: {}, // 创建店铺信息表格
      form: { // 完善店铺 表单
        id: '' // 店铺ID
      },
      saleCategoryList: [], // 经营的类型
      salesArea: [], // 销售地区
      currencyList: [] // 货币
    }
  },

  computed: {
    ...mapGetters([
      'roleCode'
    ])
  },

  created() {
    /** 获取用户信息,填充表格 */
    getUserInfo().then((res) => {
      if (res.code === 0) {
        this.userInfo = res.data
      }
    })
    /** 获取经营类型 */
    getList().then(res => {
      this.saleCategoryList = res.data
    })

    /** 获取销售地区 */
    getCountryList().then(res => {
      this.salesArea = res.data
    })

    /** 获取货币类型 */
    getCurrencyList().then(res => {
      const currencyList = res.data.map(item => {
        return {
          ...item,
          showName: `${item.currencyCode}(${item.currencyName})`
        }
      })
      currencyList.sort((a, b) => {
        return a.sort - b.sort
      })
      this.currencyList = currencyList
    })
  },

  mounted() {
    this.shopList()
    this.mask(false)
    getUserDate().then((res) => {
      if (res.code === 0) {
        // 不过期
        this.mask(false)
      } else {
        // 过期
        this.mask(true)
      }
    })
  },

  beforeRouteLeave(to, from, next) {
    // 在导航离开渲染该组件的对应路由时调用
    this.mask(false)
    next()
  },

  destroyed() {
    this.mask(false)
  },

  methods: {
    /** 创建店铺 */
    createShop() {
      const that = this
      this.$refs.createShopForm.validate(valid => {
        if (valid) {
          that.showLoading = true
          editform(this.createShopForm).then((res) => {
            if (res.code === 0) {
              // 判断用户是否是第一次创建店铺
              shopInfo().then(res => {
                if (res.code === 0) {
                  that.showLoading = false
                  message.success('创建店铺成功')
                  this.createShopModal = false
                  this.shopList()
                } else {
                  that.showLoading = false
                  that.$router.push({ name: 'shopGuidance' })
                }
              })
            } else {
              that.showLoading = false
              message.error(res.msg)
            }
          }).catch((res) => {
            this.showLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    /** 完善信息 */
    editShop() {
      // const that = this
      this.$refs['formTable'].validate((valid) => {
        if (valid) {
          const data = Object.assign({}, this.form)
          // selectValue只保留最后一项
          data.oneSalesAreaId = this.form.salesAreaId[0]
          data.twoSalesAreaId = this.form.salesAreaId[1]
          this.showLoading = true
          const timestamp = new Date().getTime()
          console.log(timestamp)
          updateShop(data).then((res) => {
            const timestamp = new Date().getTime()
            console.log(timestamp, 'callbackTime')

            if (res.code === 0) {
              this.showLoading = false
              message.success('完善信息成功')
              this.editShopModal = false
              this.createShopModal = false
              this.shopList()
            } else {
              message.error(res.msg)
              this.showLoading = false
              this.createShopModal = false
            }
          }).catch(() => {
            this.showLoading = false
            this.createShopModal = false
            message.error('服务器异常，请稍后重试')
          })
        }
      })
    },
    /** select搜索 */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    /** 店铺Handle */
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.shopList({
        size: pagination.pageSize,
        current: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      })
    },
    /** 前往店铺 */
    goShop(record) {
      checkForCompleteInformation(record.id).then(res => {
        if (res.code === 0) {
          getMd5Token().then((res) => {
            if (res.code === 0) {
              window.location.href = `//master.vllshop.com/api/AutoLoginer?hashkey=${res.data}&userEmail=${this.userInfo.email}&shopId=${record.id}&host=${window.location.host}`
            }
          })
        } else {
          this.form.id = record.id
          this.editShopModal = true
        }
      })
    },

    /** 获取店铺列表 */
    shopList(params = {}) {
      this.loading = true
      getShopList({
        size: 10,
        ...params,
        ...this.search
      }).then(({ data }) => {
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.loading = false
        this.listData = data.records
        this.pagination = pagination
      })
    },

    /**
     * @title 列表查询
     * @param search 查询对象
     */
    handleSearch() {
      this.pagination.current = 1 // 重置第一页

      this.search = this.searchObj

      this.shopList()
    },

    /** 删除店铺 */
    delShop(item) {
      sendShopDeleteVerificationCode().then((res) => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: res.msg
          })
        } else {
          this.$message({
            type: 'info',
            message: res.msg
          })
        }
      })
      const h = this.$createElement
      this.$prompt('此操作会永久删除店铺', '警告', {
        customClass: 'delShopMsg',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[\d]+$/,
        inputPlaceholder: '请输入验证码',
        message: h('p', null, [
          h('span', { style: 'color: red' }, '此操作会永久删除店铺 '),
          h('i', { style: 'color: teal' }, item.shopName),
          h('br'),
          h('span', { style: 'color: black' }, `验证码已发送到您的手机号：`),
          h('span', { style: 'color: teal' }, `${this.userInfo.phone}`)
        ]),
        inputErrorMessage: '请输入正确的验证码'
      }).then(({ value }) => {
        delShopFormId(item.id, value).then((res) => {
          if (res.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.$router.go(0)
          } else {
            this.$message({
              type: 'error',
              message: '删除失败请稍后再试'
            })
          }
        })
      })
      // .catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '删除失败请稍后再试'
      //   })
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
  .split::before{
    content: ' | ';
    color: #000;
    pointer-events: none;
  }
  .search{
    margin-left: 8px;
    margin-bottom: 8px;
  }
  .table-name{
    display: flex;
    align-items: center;
    .poit{
      width: 8px;
      height: 8px;
      background: #000;
      border-radius: 20px;
    }
    .shop-logo{
      margin: 0 8px;
      .logo{
        width: 77px;
        height: 77px;
        object-fit: contain;
        object-position: center;
      }
    }
    .text{
    }
    .textcolor{
      color: #FBC900;
    }
  }
</style>
